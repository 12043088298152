import { type PropsWithChildren, useContext, useMemo } from 'react'
import { createContext } from 'react'

import type { DeviceType } from '@knauf-group/ct-shared-nextjs/utils/deviceType'

type DeviceTypeContextType = {
  deviceType: DeviceType
}

const DeviceTypeContext = createContext<DeviceTypeContextType>({
  deviceType: 'desktop',
})

export const DeviceTypeProvider = ({
  children,
  deviceType,
}: PropsWithChildren<{ deviceType: DeviceType }>) => {
  const value = useMemo(
    () => ({
      deviceType,
    }),
    [deviceType],
  )

  return <DeviceTypeContext.Provider value={value}>{children}</DeviceTypeContext.Provider>
}

export const useDeviceTypeContext = () => useContext(DeviceTypeContext)
